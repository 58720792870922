$body-bg: #100519;
$body-color: #fff;
$primary: #ff9400;
$dark: #39204d;

$theme-colors: (
        "primary": $primary,
        "danger": #ff0000,
        "info": #59dbef,
);

//tabs
$nav-tabs-border-color: darken($primary, 20%);
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: darken($primary, 20%);
$nav-tabs-link-active-border-color: $nav-tabs-link-active-bg $nav-tabs-link-active-bg $nav-tabs-link-active-bg;
$nav-tabs-link-hover-border-color: $nav-tabs-link-active-bg $nav-tabs-link-active-bg $nav-tabs-link-active-bg;

//cards
$card-color: $body-color;
$card-bg: lighten($body-bg, 5%);

@font-face {
  font-family: 'warface_regular';
  font-style: normal;
  font-weight: 300;
  src: local('^'),
  url('./fonts/WarFace_Regular2.ttf') format('truetype');
}

@font-face {
  font-family: 'warface_stat';
  font-style: normal;
  font-weight: 300;
  src: local('^'),
  url('./fonts/Static.ttf') format('truetype');
}

body {
  background: url(/images/bg.png);
  background-position: center top;
  -webkit-background-size: auto;
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.up-btn {
  position: fixed;
  right: 5%;
  bottom: 5%;
}

.navbar-brand {
  font-family: warface_regular;
}

.menu-link {
  cursor: pointer;
  transition: opacity .5s;
  &:hover {
    opacity: .8;
  }
}

@import "../node_modules/bootstrap/scss/bootstrap";
